export const dataEquipo = {
    img: `${process.env.REACT_APP_PUBLIC_BACKEND_URL}/wp-content/uploads/2023/03/markus-spiske-O70hwncRDC8-unsplash-scaled.jpg`,
    title: "COMAGRO, se forma por Personas de Excelencia",
    subheading:
      "Contamos con un Equipo Técnico multidisciplinario, de gran prestigio y trayectoria en la Agricultura nacional e internacional para el desarrollo en terreno de toda su línea de Productos.",
    equipo: [
      {
        nombre: "Equipo Técnico",
        color: "bg-hero-color",
        personas: [
          {
            nombre: "Rodrigo Urizar",
            cargo: "GERENTE TÉCNICO",
            mail: "rurizar@comagrochile.cl",
            tel: "+56 9 6909 2045",
            img: `${process.env.REACT_APP_PUBLIC_BACKEND_URL}/wp-content/uploads/2023/03/01_Rodrigo-Utizar-e1679420813808.jpeg`,
          },
          {
            nombre: "Manuel Prieto",
            cargo: "GERENTE COMERCIAL Y DESARROLLO",
            mail: "mprieto@comagrochile.cl",
            tel: "+56 9 9998 8385",
            img: `${process.env.REACT_APP_PUBLIC_BACKEND_URL}/wp-content/uploads/2023/03/Gabriel-Prieto-Comagro-e1679346058176.jpg`,
          },
          {
            nombre: "Valentina Laurido",
            cargo: "JEFA DIFUSIÓN Y DESARROLLO",
            mail: "vlaurido@comagrochile.cl",
            tel: "+56 9 8216 8243",
            img: `${process.env.REACT_APP_PUBLIC_BACKEND_URL}/wp-content/uploads/2023/03/Valentina_Laurido-Comagro-e1679346089387.jpg`,
          },
          {
            nombre: "Pía Bastías",
            cargo: "JEFE TÉCNICO NACIONAL",
            mail: "piabastias@comagrochile.cl",
            tel: "+56 9 9452 4669",
            img: `${process.env.REACT_APP_PUBLIC_BACKEND_URL}/wp-content/uploads/2023/03/Pia_Bastias_Comagro-1-e1679345928217.jpg`,
          },
          {
            nombre: "Maximiliano Figueroa",
            cargo: "ENCARGADO DE INVESTIGACIÓN Y DESARROLLO",
            mail: "mfigueroa@comagrochile.cl",
            tel: "+56 9 8381 8509",
            img: `${process.env.REACT_APP_PUBLIC_BACKEND_URL}/wp-content/uploads/2023/03/Maximiliano_Figueroa-e1679346030154.jpg`,
          },
          {
            nombre: "Cristian Lacalle",
            cargo: "CHIEF PRODUCT MANAGER",
            mail: "clacalle@comagrochile.cl",
            tel: "",
            img: `${process.env.REACT_APP_PUBLIC_BACKEND_URL}/wp-content/uploads/2023/06/CRISTIAN-LACALLE-e1686744504856.jpeg`,
          },
        ],
      },
      {
        nombre: "Equipo Comercial",
        color: "bg-white",
        personas: [
          {
            nombre: "Francisca Villagrán",
            cargo: "ZONA COMERCIAL REGIÓN METROPOLITANA",
            mail: "fvillagran@comagrochile.cl",
            tel: "+56 9 9886 3633",
            img: `${process.env.REACT_APP_PUBLIC_BACKEND_URL}/wp-content/uploads/2023/03/1-Francisca-Villagran-Comagro-e1679345895893.jpg`,
          },
          {
            nombre: "Cristobal Letelier",
            cargo: "ZONA COMERCIAL REGIÓN METROPOLITANA",
            mail: "cletelier@comagrochile.cl",
            tel: "+56 9 6703 5394",
            img: `${process.env.REACT_APP_PUBLIC_BACKEND_URL}/wp-content/uploads/2023/03/2-Cristobal-Letelier-Comagro-e1679345855483.jpg`,
          },
          {
            nombre: "Raiumundo Lante",
            cargo: "PRODUCT MANAGER",
            mail: "rlante@comagrochile.cl",
            tel: "",
            img: `${process.env.REACT_APP_PUBLIC_BACKEND_URL}/wp-content/uploads/2023/04/raimundo-lante-e1681421918244.png`,
          },
          {
            nombre: "Felipe Venegas",
            cargo: "ZONA COMERCIAL SEXTA REGIÓN",
            mail: "fvenegas@comagrochile.cl",
            tel: "+56 9 4239 1765",
            img: `${process.env.REACT_APP_PUBLIC_BACKEND_URL}/wp-content/uploads/2023/03/4-Felipe-Venegas-Comagro-e1679351678983.jpg`,
          },
          {
            nombre: "Francisco Campos",
            cargo: "ZONA COMERCIAL SEXTA REGIÓN",
            mail: "fcampos@comagrochile.cl",
            tel: "+56 9 8239 1170",
            img: `${process.env.REACT_APP_PUBLIC_BACKEND_URL}/wp-content/uploads/2023/03/5-Francisco-Campos-Comagro-e1679351716555.jpg`,
          },
          {
            nombre: "Sebastián Lezaeta",
            cargo: "ZONA COMERCIAL SEXTA REGIÓN",
            mail: "slezaeta@comagrochile.cl",
            tel: "+56 9 8239 1141",
            img: `${process.env.REACT_APP_PUBLIC_BACKEND_URL}/wp-content/uploads/2023/03/6-Sebastian-Lezaeta-Comagro-e1679351732384.jpg`,
          },
          {
            nombre: "Martín Vidal",
            cargo: "ZONA COMERCIAL SEXTA REGIÓN",
            mail: "mvidal@comagrochile.cl",
            tel: "+56 9 9327 4064",
            img: `${process.env.REACT_APP_PUBLIC_BACKEND_URL}/wp-content/uploads/2023/03/7-Martin-Vidal-Comagro-e1679351791507.jpg`,
          },
          {
            nombre: "Boris Urzúa",
            cargo: "ZONAL COMERCIAL SEXTA REGIÓN",
            mail: "burzua@comagrochile.cl",
            tel: "+56 9 3918 5598",
            img: `${process.env.REACT_APP_PUBLIC_BACKEND_URL}/wp-content/uploads/2023/03/8-Boris-Urzua-Comagro-e1679351808302.jpg`,
          },
          {
            nombre: "Erwin Rolfs",
            cargo: "ZONAL COMERCIAL SEXTA REGIÓN",
            mail: "erolfs@comagrochile.cl",
            tel: "+56 9 9821 8585",
            img: `${process.env.REACT_APP_PUBLIC_BACKEND_URL}/wp-content/uploads/2023/03/9-6-e1679351830773.jpg`,
          },
          {
            nombre: "Vicente Fuenzalida",
            cargo: "ZONAL COMERCIAL SÉPTIMA REGIÓN",
            mail: "vfuenzalida@comagrochile.cl",
            tel: "+56 9 3958 4388",
            img: `${process.env.REACT_APP_PUBLIC_BACKEND_URL}/wp-content/uploads/2023/03/10-Vicente-Fuenzalida-Comagro-e1679351923321.jpg`,
          },
          {
            nombre: "Eduardo Heinsohn",
            cargo: "ZONAL COMERCIAL SÉPTIMA REGIÓN",
            mail: "eduardoh@comagrochile.cl",
            tel: "+56 9 4227 5598",
            img: `${process.env.REACT_APP_PUBLIC_BACKEND_URL}/wp-content/uploads/2023/03/11-Eduardo-Heinsohn-Comagro-e1679351940781.jpg`,
          },
          {
            nombre: "Miguel Ruz",
            cargo: "ZONAL COMERCIAL SÉPTIMA REGIÓN",
            mail: "mruz@comagrochile.cl",
            tel: "+56 9 3958 4381",
            img: `${process.env.REACT_APP_PUBLIC_BACKEND_URL}/wp-content/uploads/2023/03/12-Miguel-Ruz-Comagro-e1679351956697.jpg`,
          },
          {
            nombre: "Juan Pablo Peñafiel",
            cargo: "ZONAL COMERCIAL SEXTA REGIÓN",
            mail: "jppeñafiel@comagrochile.cl",
            tel: "+56 9 5011 2987",
            img: `${process.env.REACT_APP_PUBLIC_BACKEND_URL}/wp-content/uploads/2023/03/13-Juanpablo-Penafiel-Comagro-e1679352029981.jpg`,
          },
          {
            nombre: "Alberto Ecclefield",
            cargo: "ZONA COMERCIAL",
            mail: "aclefiel@comagrochile.cl",
            tel: "+56 9 9829 6319",
            img: `${process.env.REACT_APP_PUBLIC_BACKEND_URL}/wp-content/uploads/2023/03/15-Alberto-Ecclefield-e1679420890481.jpg`,
          },
          {
            nombre: "Felipe Larraín",
            cargo: "DIRECTOR COMERCIAL",
            mail: "flarrain@comagrochile.cl",
            tel: "",
            img: `${process.env.REACT_APP_PUBLIC_BACKEND_URL}/wp-content/uploads/2023/03/5-Felipe-Larrain-Comagro-e1679346228976.jpg`,
          },
          {
            nombre: "Daniela Prieto",
            cargo: "ZONAL COMERCIAL QUINTA REGIÓN",
            mail: "dprieto@comagrochile.cl",
            tel: "+56 9 5739 1471",
            img: `${process.env.REACT_APP_PUBLIC_BACKEND_URL}/wp-content/uploads/2023/03/17-Daniela_Prieto-e1679352108559.jpg`,
          },
          {
            nombre: "Paulo Chaparro",
            cargo: "ZONAL COMERCIAL SEPTIMA REGIÓN SUR",
            mail: "pchaparro@comagrochile.cl",
            tel: "+56 9 3270 9288",
            img: `${process.env.REACT_APP_PUBLIC_BACKEND_URL}/wp-content/uploads/2023/03/18-Paulo_Chaparro-e1679352149162.jpg`,
          },
          {
            nombre: "Tomás Costabal",
            cargo: "ZONA COMERCIAL",
            mail: "tcostabal@comagrochile.cl",
            tel: "+56 9 9837 5141",
            img: `${process.env.REACT_APP_PUBLIC_BACKEND_URL}/wp-content/uploads/2023/03/19-tomas-costabal-e1679352165525.jpeg`,
          },
          {
            nombre: "Patricia Artigas",
            cargo: "ZONAL COMERCIAL OCTAVA REGIÓN SUR",
            mail: "",
            tel: "",
            img: `${process.env.REACT_APP_PUBLIC_BACKEND_URL}/wp-content/uploads/2023/03/21-patricia-artigas-e1679352222300.jpeg`,
          },
          {
            nombre: "Roberto Silva",
            cargo: "ZONA COMERCIAL",
            mail: "rsilva@comagrochile.cl",
            tel: "",
            img: `${process.env.REACT_APP_PUBLIC_BACKEND_URL}/wp-content/uploads/2023/03/Roberto-Silva-e1679352293944.png`,
          },
          {
            nombre: "Juan Pablo Donoso",
            cargo: "COMERCIAL ZONAL",
            mail: "jpdonoso@comagrochile.cl",
            tel: "",
            img: `${process.env.REACT_APP_PUBLIC_BACKEND_URL}/wp-content/uploads/2023/04/Juan-Pablo-Donoso.jpg`,
          },
        ],
      },
      {
        nombre: "Equipo Bodega y Logística",
        color: "bg-hero-color",
        personas: [
          {
            nombre: "Daniel Díaz",
            cargo: "JEFE OPERACIONES",
            mail: "ddiaz@comagrochile.cl",
            tel: "+56 9 8908 3866",
            img: `${process.env.REACT_APP_PUBLIC_BACKEND_URL}/wp-content/uploads/2023/03/1-Daniel-Diaz-Comagro-e1679351441193.jpg`,
          },
          {
            nombre: "Aquiles Rojas",
            cargo: "JEFE BODEGA",
            mail: "",
            tel: "",
            img: `${process.env.REACT_APP_PUBLIC_BACKEND_URL}/wp-content/uploads/2023/03/2-Aquiles-Rojas-Comagro-e1679351465543.jpg`,
          },
          {
            nombre: "Gonzalo Padilla",
            cargo: "ENCARGADO LOGÍSTICA",
            mail: "",
            tel: "",
            img: `${process.env.REACT_APP_PUBLIC_BACKEND_URL}/wp-content/uploads/2023/03/3-Gonzalo-Padilla-Comagro-e1679351489642.jpg`,
          },
          {
            nombre: "Cristián Quinteros",
            cargo: "ENCARGADO LOGÍSTICA",
            mail: "",
            tel: "",
            img: `${process.env.REACT_APP_PUBLIC_BACKEND_URL}/wp-content/uploads/2023/03/4-Cristian-Quinteros-Comagro-e1679351508743.jpg`,
          },
          {
            nombre: "Héctor León",
            cargo: "GERENTE DE ADMINISTACIÓN Y LOGÍSTICA",
            mail: "hleon@comagrochile.cl",
            tel: "+56 9 4227 5599",
            img: `${process.env.REACT_APP_PUBLIC_BACKEND_URL}/wp-content/uploads/2023/03/4-Hector-Leon-Comagro-e1679346171714.jpg`,
          },
          {
            nombre: "Katherinne Galaz",
            cargo: "COORDINADORA LOGÍSTICA",
            mail: "kgalaz@comagrochile.cl",
            tel: "+56 9 7823 5267",
            img: `${process.env.REACT_APP_PUBLIC_BACKEND_URL}/wp-content/uploads/2023/03/6-4-e1679351560581.jpg`,
          },
          {
            nombre: "Andrea Labra",
            cargo: "ANALISTA DE LOGÍSTICA",
            mail: "alabra@comagrochile.cl",
            tel: "",
            img: `${process.env.REACT_APP_PUBLIC_BACKEND_URL}/wp-content/uploads/2023/08/andrea-labra.jpeg`,
          },
          {
            nombre: "Jorge Ortiz",
            cargo: "ENCARGADO DE LOGISTICA",
            mail: "",
            tel: "",
            img: `${process.env.REACT_APP_PUBLIC_BACKEND_URL}/wp-content/uploads/2023/03/8-Jorge-Ortiz.png`,
          },
          {
            nombre: "Manuel Ortiz",
            cargo: "ENCARGADO DE LOGISTICA",
            mail: "",
            tel: "",
            img: `${process.env.REACT_APP_PUBLIC_BACKEND_URL}/wp-content/uploads/2023/03/9-Manuel-Ortiz-e1679420985522.png`,
          },         
          {
            nombre: "Javiera Valenzuela",
            cargo: "ANALISTA DE FACTURACIÓN",
            mail: "jvalenzuela@comagrochile.cl",
            tel: "",
            img: `${process.env.REACT_APP_PUBLIC_BACKEND_URL}/wp-content/uploads/2023/08/javiera-valenzuela.jpeg`,
          },
        ],
      },
      {
        nombre: "Equipo Administración General",
        color: "bg-white",
        personas: [
          {
            nombre: "Benjamín Troncoso",
            cargo: "GERENTE GENERAL",
            mail: "btroncoso@comagrochile.cl",
            tel: "",
            img: `${process.env.REACT_APP_PUBLIC_BACKEND_URL}/wp-content/uploads/2023/03/1-Benjamin-Troncoso-Comagro-e1679345805532.jpg`,
          },
          {
            nombre: "Luz María Vergara",
            cargo: "JEFA COBRANZA NACIONAL",
            mail: "lvergara@comagrochile.cl",
            tel: "+56 9 9889 5946",
            img: `${process.env.REACT_APP_PUBLIC_BACKEND_URL}/wp-content/uploads/2023/03/2-Luz-Maria-Vergara-Comagro-e1679345716929.jpg`,
          },
          {
            nombre: "Claudia Arias",
            cargo: "ASISTENTE DE ADMINISTRACIÓN Y FINANZAS",
            mail: "carias@comagrochile.cl",
            tel: "+56 9 3191 8698",
            img: `${process.env.REACT_APP_PUBLIC_BACKEND_URL}/wp-content/uploads/2023/03/10-clauda_arias-e1679346123969.jpeg`,
          },
          {
            nombre: "Héctor León",
            cargo: "GERENTE DE ADMINISTACIÓN Y LOGÍSTICA",
            mail: "hleon@comagrochile.cl",
            tel: "+56 9 4227 5599",
            img: `${process.env.REACT_APP_PUBLIC_BACKEND_URL}/wp-content/uploads/2023/03/4-Hector-Leon-Comagro-e1679346171714.jpg`,
          },
          {
            nombre: "Felipe Larraín",
            cargo: "DIRECTOR COMERCIAL",
            mail: "flarrain@comagrochile.cl",
            tel: "",
            img: `${process.env.REACT_APP_PUBLIC_BACKEND_URL}/wp-content/uploads/2023/03/5-Felipe-Larrain-Comagro-e1679346228976.jpg`,
          },
          {
            nombre: "Eva Arías",
            cargo: "CONTABILIDAD Y TESORERIA",
            mail: "earias@comagrochile.cl",
            tel: "+56 9 3397 8005",
            img: `${process.env.REACT_APP_PUBLIC_BACKEND_URL}/wp-content/uploads/2023/03/6-Eva_Arias_Comagro-e1679346285261.jpg`,
          },
/*           {
            nombre: "Marie Laure Pocheron",
            cargo: "GERENTE DE MARKETING",
            mail: "mlpocheron@comagrochile.cl",
            tel: "+56 9 5212 5274",
            img: `${process.env.REACT_APP_PUBLIC_BACKEND_URL}/wp-content/uploads/2023/03/7-1-e1679346331624.jpg`,
          }, */
          {
            nombre: "Cristián Paredes",
            cargo: "CONTADOR GENERAL",
            mail: "cparedes@comagrochile.cl",
            tel: "+56 9 7389 0656",
            img: `${process.env.REACT_APP_PUBLIC_BACKEND_URL}/wp-content/uploads/2023/03/8-5-e1679346366304.jpg`,
          },
          {
            nombre: "Laura Lante",
            cargo: "ENCARGADA DE COBRANZA Y RRHH",
            mail: "llante@comagrochile.cl",
            tel: "+56 9 8157 2176",
            img: `${process.env.REACT_APP_PUBLIC_BACKEND_URL}/wp-content/uploads/2023/03/9-Laura_Lante-e1679346400678.jpg`,
          },
          {
            nombre: "Bárbara Pérez",
            cargo: "ASISTENTE DE COBRANZA",
            mail: "bperez@comagrochile.cl",
            tel: "+56 9 3953 4013",
            img: `${process.env.REACT_APP_PUBLIC_BACKEND_URL}/wp-content/uploads/2023/03/11-Barbara-Perez-e1679346496697.jpg`,
          },
          {
            nombre: "Alfonso Álamos",
            cargo: "GERENTE DE FINANZAS",
            mail: "aalamos@comagrochile.cl",
            tel: "",
            img: `${process.env.REACT_APP_PUBLIC_BACKEND_URL}/wp-content/uploads/2023/12/alamos.jpeg`,
          },
        ],
      },
    ],
  };
  export const dataHeroSoluciones = [
    {
      category: 21,
      title: "Nutrición Vegetal",
      text: "Productos diseñados para la corrección o aporte de los distintos elementos nutricionales requeridos por las plantas para obtener una producción rentable y de calidad.",
    },
    {
      category: 35,
      title: "Bioestimulantes",
      text: "Es una línea de productos capaces de generar una respuesta en el crecimiento y actividad metabólica de las plantas, mejorando la eficiencia de los cultivos y frutales, tanto en la absorción y asimilación de nutrientes, como en la tolerancia a estrés biótico o abiótico, mejorando sus características fisiológicas y agronómicas."
    },
    {
      category: 36,
      title: "Correctores<br>Foliares",
      text: `Productos formulados para aplicaciones foliares con el objetivo de prevenir y corregir deficiencias de diferentes elementos en frutales o cultivos.
  
      Para el diseño y desarrollo de estos productos, es fundamental la utilización de materias primas nobles y complejantes específicos para cada elemento, con el objetivo de asegurar la corrección nutricional y la compatibilidad en mezcla con otros productos.`
    },
    {
      category: 38,
      title: "Enraizantes",
      text: `Productos cuyo objetivo es promover el crecimiento de un buen sistema radicular.
      Estos productos son formulados en base a compuestos orgánicos específicos que favorecen este proceso.
      `
    },
    {
      category: 37,
      title: "Inductores de Defensa",
      text: ""
    },
    {
      category: 39,
      title: "Terminación de<br>Fruta",
      text: ""
    },
    {
      category: 22,
      title: "Enmiendas<br>de Suelo",
      text: "Productos que aportan elementos nutritivos y compuestos orgánicos que mejoran la estructura, composición y fertilidad del suelo, para un buen desarrollo del sistema radicular."
    },
    {
      category: 23,
      title: "Protección<br>Vegetal",
      text: `Son productos en base a extractos vegetales o microorganismos benéficos, formulados con el fin de solucionar problemas sanitarios o desórdenes fisiológicos sin generar residuos químicos o metabolitos detectables en las frutas y hortalizas. Estos productos no tienen carencias ni plazos de reingreso a los huertos
      Son Productos diseñados para enfrentar las nuevas y crecientes restricciones de los mercados de destino.`
    },
    {
      category: 24,
      title: "Línea<br>Orgánica",
      text: "Productos autorizados que cuentan con certificados vigentes para el uso en producción orgánica"
    },
    {
      category: 26,
      title: "Atrayentes Polinizadores",
      text: "Productos formulados para mejorar la eficiencia, en términos de cobertura y absorción de pesticidas, correctores nutricionales, bioestimulantes, reguladores de crecimiento y herbicidas."
    },
    {
      category: 41,
      title: "Otros",
      text: ""
    },
    {
      category: 40,
      title: "Correctores Nutricionales",
      text: ""
    },
   
  ];
export const posts = [
    {
        title: "ASESORÍAS TÉCNICAS",
        img: `${process.env.REACT_APP_PUBLIC_BACKEND_URL}/wp-content/uploads/2023/03/hossein-ghaem-mtq18OS02Jg-unsplash-scaled.jpg`,
        content: "Nuestro departamento técnico llevará a cabo una asesoría integral y en terreno para acompañar a nuestros clientes durante todo el ciclo de su cultivo."
    },
    {
        title: "AGRICULTURA DE PRECISIÓN",
        img: `${process.env.REACT_APP_PUBLIC_BACKEND_URL}/wp-content/uploads/2023/03/andrea-cairone-Jkfa0AzUEUQ-unsplash-scaled.jpg`,
        content: "Gracias a nuestras marcas asociadas, podemos monitorear y analizar parámetros agronómicos y medioambientales de manera remota."
    },
    {
        title: "SISTEMAS DE RIEGO",
        img: `${process.env.REACT_APP_PUBLIC_BACKEND_URL}/wp-content/uploads/2023/03/www-zanda-photography-RBdE3jv5y68-unsplash-scaled.jpg`,
        content: "El agua es el factor determinante en la producción agraria. Conocer la disponibilidad y optimizar su uso para lograr mayores producciones es posible con nuestras soluciones a la medida."
    },
    {
        title: "LABORATORIO",
        img: `${process.env.REACT_APP_PUBLIC_BACKEND_URL}/wp-content/uploads/2023/03/chuttersnap-UmncJq4KPcA-unsplash-scaled.jpg`,
        content: "Ofrecemos completos análisis de laboratorio para nuestros clientes, tanto análisis foliares como análisis de suelo."
    },
    {
        title: "BODEGAJE Y DISTRIBUCIÓN",
        img: `${process.env.REACT_APP_PUBLIC_BACKEND_URL}/wp-content/uploads/2023/03/hannes-egler-nT4k2JDtwTQ-unsplash-scaled.jpg`,
        content: "Completo servicio de bodegaje, con gran capacidad de almacenaje, más un completo servicio de seguridad. Gestionamos mediante SAP el control de caducidad de productos que aseguran la trazabilidad de nuestros productos."
    },
    {
        title: "FINANCIAMIENTO",
        img: `${process.env.REACT_APP_PUBLIC_BACKEND_URL}/wp-content/uploads/2023/03/micheile-dot-com-SoT4-mZhyhE-unsplash-scaled.jpg`,
        content: "Contamos con el respaldo y las garantías necesarias para convenir plazos de compra acordes a las necesidades de nuestros clientes."
    },
    
];
export const dataHome = {
    headingHero: '"Socios Desde el Primer Día"',
    subHeadingHero: `  Comagro nace de la necesidad de generar un cambio en el modelo
    tradicional de venta de insumos agrícolas en Latinoamérica, con un
    claro enfoque en el agricultor.`,
    btnHero: 'soluciones/:35',

    carousel: [
      {
        title:"BIOESTIMULANTES",
        href: 'soluciones/:35',
        img: "/wp-content/uploads/2023/03/juraj-filipeje-V_X0uBi_8eQ-unsplash-scaled.jpg"
      },
      {
        title:"CORRECTORES NUTRICIONALES",
        href: 'soluciones/:40',
        img: '/wp-content/uploads/2023/03/sean-foster-WOJazEMoUKQ-unsplash-scaled.jpg'
      },  
      {
        title:"PROTECCION VEGETAL",
        href: 'soluciones/:23',
        img: '/wp-content/uploads/2023/03/kelly-sikkema-94_X77ZnRRo-unsplash-scaled.jpg'
      }
      ,  
      {
        title:"SUELO",
        href: 'soluciones/:22',
        img: '/wp-content/uploads/2023/03/francesco-gallarotti-ruQHpukrN7c-unsplash-scaled.jpg'
      },
      {
        title:"OTROS",
        href: 'soluciones/:41',
        img: '/wp-content/uploads/2023/03/joshua-harris-AdGsjzdmibM-unsplash-scaled.jpg'
      }
    ]
  }
