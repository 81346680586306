import React from "react";
import { dataHome } from "../../utils/data";
const HeroHome = () => {
  return (
    <>
      <section className="bg-hero-color lg:px-[138px] py-[20px] px-[20px]">
        <div className="flex flex-col flex-lg-row-reverse py-6 mx-auto  lg:py-0 lg:flex-row justify-content-center justify-center align-center">
          <div className="flex justify-content-center lg:justify-content-center items-center justify-start p-2 mt-8 lg:mt-0 h-full sm:h-80 lg:h-full xl:h-full 2xl:h-full">
            <img
              src={require("../../assets/HeroImage.png")}
              alt=""
              className="object-contain h-72 sm:h-80 lg:h-full xl:h-full 2xl:h-full pr-0 lg:pr-[138px]"
            />
          </div>
          <div className="flex flex-col lg:w-full lg:max-w-[50%] justify-content-center  justify-center  sm:py-12 lg:text-left px-0 mx-0">
            <h1 className="home-hero-title-home comagro-blue ">
              {dataHome.headingHero}
            </h1>
            <p className="mt-6 mb-0 subheading-hero comagro-blue sm:mb-12">
             {dataHome.subHeadingHero}
            </p>
            <div className="flex flex-col space-y-4 sm:items-center sm:justify-center sm:flex-row sm:space-y-0 sm:space-x-4 lg:justify-start">
              <a
                rel="noopener noreferrer"
                href={dataHome.btnHero}
                className="px-8 py-3 btn-comagro"
              >
                Cotiza tus productos
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default HeroHome;
