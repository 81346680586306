import { useState } from "react";
import { Dropdown, DropdownButton, Image, Stack } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import { Link } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";

function Header() {
  const [btnActive, setBtnActive] = useState("chile");
  return (
    <Navbar collapseOnSelect expand="lg" className="App-header">
      <Container className="header-style">
        <Navbar.Brand href="/">
          <Image
            src={require("../../../assets/logo-comagro2.png")}
            style={{ width: "100px", height: "37px" }}
          ></Image>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="offcanvasNavbar-expand-xl" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto Navbar-header">
            <NavDropdown title="Quiénes Somos" id="collasible-nav-dropdown">
              <Stack gap={2}>
                <NavDropdown.Item href="/quienes-somos">
                  Quiénes Somos
                </NavDropdown.Item>
                <NavDropdown.Item href="/equipo">Equipo</NavDropdown.Item>
              </Stack>
            </NavDropdown>
            <NavDropdown title="Soluciones" id="collasible-nav-dropdown">
              <Stack gap={2}>
                {/* <DropdownButton
                  drop={"end"}
                  title="Nutrición Vegetal"
                  className="dropdown-toggle-end"
                >
                  <Stack gap={2}>
                    <Dropdown.Item href="/soluciones/:35">
                      Bioestimulantes
                    </Dropdown.Item>
                    <Dropdown.Item href="/soluciones/:36">
                      Correctores Foliares
                    </Dropdown.Item>
                    <Dropdown.Item href="/soluciones/:38">
                      Enraizantes
                    </Dropdown.Item>
                     <Dropdown.Item href="/soluciones/:0">
                     Estrés Abiótico
                     </Dropdown.Item>
                    <Dropdown.Item href="/soluciones/:37">
                      Inductores de defensa
                    </Dropdown.Item>
                    <Dropdown.Item href="/soluciones/:39">
                      Terminación de Fruta
                    </Dropdown.Item>
                    <Dropdown.Item href="/soluciones/:21">
                      Ver Todos
                    </Dropdown.Item>
                  </Stack>
                </DropdownButton> */}
                 <NavDropdown.Item href="/soluciones/:35">
                  Bioestimulantes
                </NavDropdown.Item>
                <NavDropdown.Item href="/soluciones/:40">
                  Correctores Nutricionales
                </NavDropdown.Item>
                <NavDropdown.Item href="/soluciones/:23">
                  Protección Vegetal
                </NavDropdown.Item>
                <NavDropdown.Item href="/soluciones/:22">
                  Suelo
                </NavDropdown.Item>
                <NavDropdown.Item href="/soluciones/:41">
                  Otros
                </NavDropdown.Item>
                {/*  <NavDropdown.Item href="/soluciones/:21">Limpiadores sistemas de riego</NavDropdown.Item> */}
              </Stack>
            </NavDropdown>
           {/*  <Nav.Link href="/noticias">Noticias</Nav.Link> */}
            <Nav.Link href="/contacto">Contacto</Nav.Link>
            <Nav.Link
              href="/cotizar"
              className="text-white border hover-nav-item"
            >
              Mi Cotización
            </Nav.Link>
          </Nav>
          <Nav>
            <Link
              to="http://comagrochile.cl"
              onClick={() => {
                setBtnActive("chile");
              }}
              className={
                btnActive === "chile"
                  ? "btn-country-active"
                  : "btn-country-inactive"
              }
            >
              Chile
            </Link>
            <Link
              to="http://comagroperu.pe"
              onClick={() => {
                setBtnActive("peru");
              }}
              className={
                btnActive === "peru"
                  ? "btn-country-active-peru"
                  : "btn-country-inactive-peru"
              }
            >
              Perú
            </Link>
            <Link
              to="http://comagrousa.us"
              onClick={() => {
                setBtnActive("usa");
              }}
              className={
                btnActive === "usa"
                  ? "btn-country-active-usa"
                  : "btn-country-inactive-usa"
              }
            >
              EEUU
            </Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
