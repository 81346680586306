import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import SiteHero from "../../components/Hero/siteHeadingHero";
import Products from "../../components/Soluciones/Products";
import { dataHeroSoluciones } from "../../utils/data";
import {getProductsData} from "../../utils/products"
import Spinner from "../../components/spinner";

const Soluciones = () =>{
    const [ data, setData] = useState([]);
    const [ isMounted, setMounted] = useState(false);
    const category = useParams()
    const heroImage = require('../../assets/hero-soluciones.jpg')
    const dataProducts= (dataHeroSoluciones.filter( data =>  data.category.toString() === category.category.slice(1).toString()))

    async function getData(){
        await getProductsData(50,category.category.slice(1))
        .then((response) => {
            setData(response.data);
          })
          .catch((error) => {
            console.log(error.response.data);
          });
    }
    useEffect(()=>{
        getData();
        setTimeout(() => {
            setMounted(true)
        }, 2000);
    },[category])
    return (
        <div>
            <SiteHero heading={dataProducts[0].title} subheading={dataProducts[0].text} imageurl={heroImage} />
            { data.length > 0 & isMounted ? (
            <Products data={data}/>
            ):  
            <div className="flex justify-content-center py-[128px]">  <Spinner classCss={"loader"}/> </div>
           }
        </div>
    )
}
export default Soluciones;