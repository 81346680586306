import React, { useContext, useState } from "react";
import { AppContext } from "../context";
import CartItem from "./cart-item";
import postForm from "../../utils/send-form";
import { clearCart } from "../../utils/cart";

const CartItemsContainer = () => {
  const {cart, setCart} = useContext(AppContext);
  const { cartItems } = cart || {};
  const [isClearCartProcessing, setClearCartProcessing] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [telefono, setTelefono] = useState("");
  const [empresa, setEmpresa] = useState("");
  const [comuna, setComuna] = useState("");
  const [validate, setValidate] = useState(true);
  const [enviado, setEnviado] = useState(true);
  const [alerta, setAlerta] = useState("");

  const alert = (msje) => {
    return(
      <span className="flex flex-row justify-center inline-block align-middle ml-2 text-red-600 text-[10px]">
        <b className=""> {msje} </b> <span className="ml-[4px]"> es requerido *</span>
      </span>
    )
  }
  const formAlert  = (msje) => {
    return(
      <span className="flex flex-row justify-center inline-block align-middle mr-8 text-gray-600 text-[10px]">
        <b className=""> {msje}</b> 
      </span>
    )
  }

  async function handleSubmit(){
    const title = "Desde Cotización"

    if(!name){
      setAlerta("nombre ")
      setValidate(false);
      setEnviado(true)
      return
    }
    if(!email){
      setAlerta("email ");
      setValidate(false);
      setEnviado(true)
      return
    }
    if(!telefono){
      setAlerta("teléfono ");
      setValidate(false);
      setEnviado(true)
      return
    }
    if(!message){
      setAlerta("mensaje ");
      setValidate(false);
      setEnviado(true)
      return
    }
    const msje = cartItems.map( item => `\nProducto: ${item.data.name} -  Cantidad:  ${item.quantity}\n`)
    const messageForm = message + '\n\n\nCarro: ' + msje.toString()
    setMessage(messageForm)

    setValidate(true);
    const data = {
      name,
      email,
      message: messageForm,
      comuna,
      telefono,
      empresa,
      title
    };

    const response = await postForm(data);

    if(response.data.status === "mail_sent"){
      setEnviado(!enviado) 
      setAlerta("Correo enviado con éxito.")
      setName("")
      setEmail("")
      setMessage("")
      setTelefono("")
      setComuna("")
      setEmpresa("")
      handleClearCart()
    }else{
      setEnviado(!enviado)
      setAlerta("Se ha producido un error, inténtelo nuevamente.")
    }
  
  };
  // Clear the entire cart.
  const handleClearCart = async (event) => {
    event.stopPropagation();

    if (isClearCartProcessing) {
      return;
    }

    await clearCart(setCart, setClearCartProcessing);
  };
  return (
    <div className="flex flex-col w-100 justify-center mx-auto bg-hero-color">
        <div className=" mx-auto container lg:w-[75vw] pt-20 pb-10 md:px-8 lg:px-0" style={{width:'50vw'}}>
          <div className="py-8 md:px-8 lg:px-0 ">
            <div className="flex justify-center">
              <h2 className="font-bold text-34px text-center">
                ¿En qué te podemos ayudar?
              </h2>
            </div>
            <div className="grid grid-cols-1  mx-auto gap-4 mt-5">
            <div className="relative">
              <input
                type="text"
                id="name"
                name="name"
                className="block px-2.5 pb-2.5 pt-4 w-full text-[14px] rounded-[10px] border-[2px] border-form-b bg-hero-color appearance-none hover:border-black focus:border-primary focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                required
                onChange={(e)=> {setName(e.target.value);}}
               value={name}
              />
              <label
                htmlFor="name"
                className="absolute text-[16px] duration-300 transform -translate-y-4 scale-75 top-2 origin-[0] bg-hero-color px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
              >
                Nombre
              </label>
            </div>
            <div className="relative">
              <input
                type="text"
                id="empresa"
                name="empresa"
                className="block px-2.5 pb-2.5 pt-4 w-full text-[14px] rounded-[10px] border-[2px] border-form-b bg-hero-color appearance-none hover:border-black focus:border-primary focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                onChange={(e)=> {setEmpresa(e.target.value);}}
                value={empresa}
              />
              <label
                htmlFor="empresa"
                className="absolute text-[16px] duration-300 transform -translate-y-4 scale-75 top-2  origin-[0] bg-hero-color px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
              >
                Rut Empresa
              </label>
            </div>
            <div className="relative">
              <input
                type="email"
                id="email"
                name="email"
                className="block px-2.5 pb-2.5 pt-4 w-full text-[14px] rounded-[10px] border-[2px] border-form-b bg-hero-color appearance-none hover:border-black focus:border-primary focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                required
                onChange={(e)=> {setEmail(e.target.value);}}
                value={email}
              />
              <label
                htmlFor="email"
                className="absolute text-[16px] duration-300 transform -translate-y-4 scale-75 top-2 origin-[0] bg-hero-color px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
              >
                Email
              </label>
            </div>
            <div className="relative">
              <input
                type="number"
                id="telefono"
                name="telefono"
                className="block px-2.5 pb-2.5 pt-4 w-full text-[14px] rounded-[10px] border-[2px] border-form-b bg-hero-color appearance-none hover:border-black focus:border-primary focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                required
                onChange={(e)=> {setTelefono(e.target.value);}}
                value={telefono}
              />
              <label
                htmlFor="telefono"
                className="absolute text-[16px] duration-300 transform -translate-y-4 scale-75 top-2 origin-[0] bg-hero-color px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
              >
                Teléfono
              </label>
            </div>
            <div className="relative">
            <textarea
              className="block h-32 px-2.5 pt-2 w-full text-[14px] rounded-[10px] border-[2px] border-form-b bg-hero-color appearance-none hover:border-black focus:border-primary focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              placeholder=" "
              required
              id="msje"
              name="msje"
              onChange={(e)=> {setMessage(e.target.value);}}
              value={message}
            ></textarea>
            <label
              htmlFor="msje"
              className="absolute text-[14px] duration-300 transform -translate-y-4 scale-75 top-2  origin-[0] bg-hero-color px-2 peer-focus:px-2  peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2
              peer-focus:text-[14px]
              peer-focus:scale-80  peer-focus:-translate-y-4 left-1"
            >
              Cotización (Agregar cantidad de litros o Kilogramos)
            </label>
          </div> 
            </div>
            </div>
          </div>
      {cart ? (
        <div className="flex flex-col mx-auto lg:w-1/2  gap-y-[24px] pb-10">
          <hr className="hr h-[2px]" />
          {/*Cart Items*/}
          {cartItems &&
            cartItems.map((item) => (
              <CartItem
                key={item.product_id}
                item={item}
                products={cartItems}
                setCart={setCart}
              />
            ))}

          {/*Cart Total*/}
            <div className="grid grid-cols-2 justify-between">
            {/*Checkout*/}
            <div className="">
                <button 
                onClick={() =>handleSubmit()}
                className="btn-cotizar"
                >
                  <span className="font-[12px] px-[30px]">
                    Cotizar
                  </span>
                </button>

              </div>
              {/*Clear entire cart*/}
              <div className="flex justify-end">
                <button
                  className="text-secondary bg-white border-2 border-secondary font-medium rounded-[20px] text-sm px-[40px] py-2.5  text-center"
                  onClick={(event) => handleClearCart(event)}
                  disabled={isClearCartProcessing}
                >
                  <span>
                    {!isClearCartProcessing ? "Vaciar carro" : "Vaciando..."}
                  </span>
                </button>
              </div>

            </div>
            {!validate ? alert(alerta) : null}
            {!enviado ? formAlert(alerta) : null}
        </div>
        
      ) : (
        <div className="flex justify-center">
          <h2 className="my-6 text-[12px]">
            No hay productos agregados a la cotización
          </h2>
        </div>
      )}
    </div>
  );
};

export default CartItemsContainer;
