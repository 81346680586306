import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function CardEquipo({ dataequipo }) {
  const imgStyle = {
    width:'321px',
    height:'321px',
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    borderRadius:'12px',

  } 
  const bodyStyle = {
    width:'78%',
    margin:'auto',
  } 
  return dataequipo.map((equipo) => (
    <div className="container-equipo" style={{backgroundColor: `${equipo.color === "bg-hero-color" ? '#f1f5f9' : '#fff'}`}}>
      <Container className="container-equipo-bp">
        <div className="comagro-blue h2-equipo">{equipo.nombre}</div>
        <div className="flex flex-wrap justify-content-center" style={{columnGap:'89px'}}>
        {equipo.personas.map(persona => (
        <Col sm={12} md={6} lg={4} className="card-container">
            <img className="object-cover" src={persona.img} style={imgStyle} alt=""/>
            <div style={bodyStyle}>
                <div className="card-title">{persona.nombre}</div>
                <div className="card-cargo">{persona.cargo}</div>
                <div className="card-mail">{persona.mail}</div>
                <div className="card-tel">{persona.tel}</div>
            </div>
        </Col>
        ))}
        </div>
      </Container>
    </div>
  ));
}

export default CardEquipo;
