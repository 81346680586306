import React, { useEffect, useState, useRef } from "react";
import { isEmpty } from "lodash";
import { deleteCartItem, updateCart } from "../../utils/cart";
import Spinner from "../../components/spinner"

const CartItem = ({ item, products, setCart }) => {
  const [productCount, setProductCount] = useState(item.quantity);
  const [updatingProduct, setUpdatingProduct] = useState(false);
  const [removingProduct, setRemovingProduct] = useState(false);

  /**
   * Do not allow state update on an unmounted component.
   *
   * isMounted is used so that we can set it's value to false
   * when the component is unmounted.
   * This is done so that setState ( e.g setRemovingProduct ) in asynchronous calls
   * such as axios.post, do not get executed when component leaves the DOM
   * due to product/item deletion.
   * If we do not do this as unsubscription, we will get
   * "React memory leak warning- Can't perform a React state update on an unmounted component"
   *
   * @see https://dev.to/jexperton/how-to-fix-the-react-memory-leak-warning-d4i
   * @type {React.MutableRefObject<boolean>}
   */
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;

    // When component is unmounted, set isMounted.current to false.
    return () => {
      isMounted.current = false;
    };
  }, []);

  /*
   * Handle remove product click.
   *
   * @param {Object} event event
   * @param {Integer} Product Id.
   *
   * @return {void}
   */
  const handleRemoveProductClick = (event, cartKey) => {
    event.stopPropagation();

    // If the component is unmounted, or still previous item update request is in process, then return.
    if (!isMounted || updatingProduct) {
      return;
    }

    deleteCartItem(cartKey, setCart, setRemovingProduct);
  };

  /*
   * When user changes the qty from product input update the cart in localStorage
   * Also update the cart in global context
   *
   * @param {Object} event event
   *
   * @return {void}
   */
  const handleQtyChange = (event, cartKey, type) => {
    //process funciona desde el servidor, del lado del cliente, no del navegador...cambiar por otra variable de desarrollo
    //if (process.browser) {
    if(window.localStorage.getItem("cart") !== null ){
      event.stopPropagation();
      let newQty;

      // If the previous cart request is still updatingProduct or removingProduct, then return.
      if (
        updatingProduct ||
        removingProduct ||
        ("decrement" === type && 1 === productCount)
      ) {
        return;
      }

      if (!isEmpty(type)) {
        newQty = "increment" === type ? productCount + 1 : productCount - 1;
      } else {
        // If the user tries to delete the count of product, set that to 1 by default ( This will not allow him to reduce it less than zero )
        newQty = event.target.value ? parseInt(event.target.value) : 1;
      }

      // Set the new qty in state.
      setProductCount(newQty);

      if (products.length) {
        updateCart(item?.key, newQty, setCart, setUpdatingProduct);
      }
    }
  };

  return (
    <div className="grid grid-cols-2 hover:bg-white justify-between rounded-[10px] py-3 px-6 group">
		<div  className="flex justify-start text-left ">
			<h2 className="flex align-center text-[16px] leading-[18.75px] mb-0">{item?.data?.name}</h2>
		</div>
        <div className="flex justify-end">
          <footer className="flex justify-between">
            {/*Qty*/}
            <div style={{ display: "flex", alignItems: "center" }}>
              <div className="mr-[10px] group-hover:bg-sol-grey p-[11px]" style={{ display: "flex", alignItems: "center" }}>
              <button
                className=" text-20px pr-[8px]"
                onClick={(event) =>
                handleQtyChange(event, item?.cartKey, "increment")
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-6 h-6 hover:text-primary"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 4.5v15m7.5-7.5h-15"
                  />
                </svg>
              </button>
              {productCount}
              <button
                className="text-24px pl-[8px]"
                onClick={(event) =>
                  handleQtyChange(event, item?.cartKey, "decrement")
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-6 h-6 hover:text-primary"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.5 12h-15"
                  />
                </svg>
              </button>
              </div>
              {removingProduct ? <Spinner classCss={"loader-cart"}/> : 
              <button
              className="text-22px leading-22px bg-transparent"
              onClick={(event) => handleRemoveProductClick(event, item?.key)}
            >
              <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-6 h-6 hover:text-red-700"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                />
              </svg>
            </button>}
              
            </div>
          </footer>
        </div>
    </div>
  );
};

export default CartItem;
