import { Link } from "react-router-dom";
import Carousel from "./multi-item-carousel";
import { dataHome } from "../../utils/data";

const CarouselHome = () => {
  return (
    <div
      style={{
        maxWidth: 1200,
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      <Carousel show={3}>
        {dataHome.carousel.length > 0 &&
          dataHome.carousel.map((item) => (
            <div style={{ marginInline: "15px" }}>
              <div
                style={{
                  background: `linear-gradient(0deg, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)), url(${process.env.REACT_APP_PUBLIC_BACKEND_URL+item.img}) center center / cover no-repeat`,
                  height: "312px",
                  borderRadius: "16px",
                }}
              >
                <div className="div-span-carousel">
                  <span className="span-carousel">Categoría</span>
                  <h2 className="title-carousel">{item.title}</h2>
                  <div className="btn-carousel">
                    <Link to={item.href} className="text-btn-carousel">
                      Contacto
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </Carousel>
    </div>
  );
};
export default CarouselHome;
