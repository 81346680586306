const HomeFinal = () => {
  return (
    <div
      className="season-products-div"
      style={{
        background: `linear-gradient(0deg, rgba(164, 194, 72, 1), rgba(164, 194, 72, .55)), url(${process.env.REACT_APP_PUBLIC_BACKEND_URL}/wp-content/uploads/2023/03/randy-fath-dDc0vuVH_LU-unsplash-scaled.jpg) center center / cover no-repeat`,
      }}
    >
      <h2 className="flex justify-center mb-10 text-center text-2xl text-white font-bold md:text-3xl px-1">
        Recomendados de la Temporada
      </h2>
      <div className="mx-auto grid w-fit pt-10 gap-y-20 lg:gap-x-[112px] xl:grid-cols-2">
        <div className="rounded-[12px] overflow-hidden shadow-lg max-w-[300px] max-h-[365px]">
          <div >
            <img
              className="season-image-service object-cover max-h-[202px] w-100"
              src={`${process.env.REACT_APP_PUBLIC_BACKEND_URL}/wp-content/uploads/2023/03/molistop.jpg`}
              alt="servicio img"
            />
          </div>
          <div className="season-content-card bg-white">
            <div className="service-card-title comagro-blue">MOLISTOP</div>
            <p className="season-card-content">
              MOLISTOP, reduce los contenidos de nitratos en las estructuras
              vegetales. Al frenar el crecimiento vegetativo excesivo, fomenta
              la traslocación de nutrientes hacia la fruta, para una mejor
              expresión del potencial productivo.
            </p>
          </div>
        </div>
        {/* <div className="season-products-divider "></div> */}
        <div className="rounded-[12px] overflow-hidden shadow-lg max-w-[300px] max-h-[365px]">
          <div className="max-w-[300px] max-h-[202px]">
            <img
              className="season-image-service object-cover max-h-[202px] w-100" 
              src={`${process.env.REACT_APP_PUBLIC_BACKEND_URL}/wp-content/uploads/2023/03/Quetion-Cobre.jpg`}
              alt="servicio img"
            />
          </div>
          <div className="season-content-card bg-white">
            <div className="service-card-title comagro-blue">QUETION COBRE</div>
            <p className="season-card-content">
              QUETION COBRE es fertilizante foliar y radicular, corrector
              nutricional 100% soluble, formulado en base a cobre complejado con
              ácido glucónico, lo que permite una mayor disponibilidad y rápida
              absorción por parte de la planta.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeFinal;
